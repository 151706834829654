/**
 * Supplement/overwrite rc-tree/assets/index.css styles
 */

/**
 * Overwrite broken/confliting stlyes
 */
.rc-tree {
  padding: 0 0 0 4px;
}
.rc-tree li {
  padding-bottom: 1.5px;
}
.rc-tree li .rc-tree-node-content-wrapper {
  height: initial;
}
.rc-tree li span.rc-tree-switcher {
  background: none;
}

/**
 * Overwrite node selection appearence 
 */
.rc-tree-node-selected {
  background: none;
  border: none;
  opacity: 1;
  font-weight: bold;
}

/**
  * transition animation on node expand
  */
.rc-tree-child-tree {
  display: block;
}

.node-motion {
  transition: all 0.3s;
  overflow: hidden;
}

.node-motion-enter,
.node-motion-leave-active {
  height: 0;
}

/**
 * Tree node label wrapping
 */
.rc-tree-child-tree-open > li.rc-tree-treenode-switcher-close {
  white-space: initial;
}
.rc-tree li .rc-tree-node-content-wrapper {
  display: initial;
}
