.aligner-container,
.aligner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
}
@media (min-width: 768px) {
  .custom-justify {
    justify-content: start;
  }
}
.contact-card.card {
  width: 100%;
  border: 1px solid #f0f0f0;
  background-color: #fafafa;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  padding: 20px 20px 5px 20px;
  opacity: 0.95;
  backface-visibility: hidden;
}
@media (max-width: 767px) {
  .name-style {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .contact-card.card {
    width: initial;
    min-width: 650px;
  }
}
@media (min-width: 576px) {
  .contact-card.card {
    width: initial;
    min-width: 500px;
  }
}
.headshot {
  border-radius: 80px;
  width: 160px;
  height: 160px;
  border: 1px solid grey;
  padding: 5px;
}
.section-btn {
  width: 100%;
  height: 50px;
}
.name-style {
  font-size: 30px;
  letter-spacing: 2px;
  font-weight: bold;
}
.background-img {
  position: fixed;
  width: 100%;
  min-width: 808px;
  min-height: 100%;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
