/**
 * Global CSS styles applied to the entire app
 */

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 100;
  font-size: 14px;
  height: 100%;
}

body,
.container-fluid,
#root {
  font-family: "Open Sans", sans-serif;
  height: 100%;
  margin: 0;
  font-size: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Site Colours */
.primary-color {
  color: rgb(255, 69, 0);
}

/* Text Elements */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inria Serif", serif;
}

/* Buttons */
button.clear {
  background: none;
  border: none;
}
button[type="button"]:focus {
  outline: none;
}
button[type="button"]:hover {
  cursor: pointer;
}

/* Inputs */
input.form-control[type="checkbox"] {
  height: 15px;
}
input.form-control[type="checkbox"]:focus {
  border: none;
  box-shadow: none;
}

/* Anchors */
a {
  color: rgb(255, 69, 0);
}
a:hover {
  text-decoration: none;
  color: rgb(255, 125, 0);
}
/* Global Styles */

strong,
b {
  font-weight: 700;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/**
 * brandywine editor overrides
 */
#global-editor-container {
  padding-bottom: 30px;
}
#global-editor-container .brandywine-responsive-caption {
  color: rgba(0, 0, 0, 0.7);
}
#global-editor-container strong {
  font-weight: 700;
}
#global-editor-container .brandywine-width_large {
  max-width: 850px;
}

@media (min-width: 768px) {
  #global-editor-container .brandywine-width_medium {
    max-width: 600px;
  }
  #global-editor-container .brandywine-width_small {
    max-width: 500px;
  }
}

/**
 * leaflet overrides
 */
#react-leaflet-container .leaflet-left .leaflet-control {
  margin-left: 20px;
}

/* Touch controls should have same styles  as normal click controls */
#react-leaflet-container .leaflet-touch .leaflet-bar a {
  width: 26px;
  height: 26px;
  line-height: 26px;
}
#react-leaflet-container .leaflet-touch .leaflet-control-zoom-in,
.leaflet-touch .leaflet-control-zoom-out {
  font-size: 18px;
}
#react-leaflet-container .leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
#react-leaflet-container .leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
#react-leaflet-container .leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}
